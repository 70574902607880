import React, { useEffect, useMemo } from "react"

const Booking = ({ lat, long }) => {
  const date = useMemo(() => new Date(), [])

  useEffect(() => {
    const script = document.createElement("script")
    script.async = true
    script.src =
      "https://aff.bstatic.com/static/affiliate_base/js/flexiproduct.js" +
      "?v=" +
      +date
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
      if (window.BookingAff && window.BookingAff.run) {
        window.BookingAff.run.already_ran = false
      }
    }
  }, [date, lat, long])
  return (
    <div>
      <ins
        className="bookingaff"
        data-aid="1754176"
        data-target_aid="1754176"
        data-prod="map"
        data-width="100%"
        data-height="590"
        data-lang="fr"
        data-currency="EUR"
        data-dest_id="0"
        data-dest_type="landmark"
        data-latitude={lat}
        data-longitude={long}
        data-mwhsb="1"
      >
        <a
          href="https://www.booking.com?aid=1754176"
          target="_blank"
          rel="noopener noreferrer"
        >
          Si la carte des hébergements disponibles ne s'affiche pas
          correctement, vérifiez votre connexion internet ou allez directement
          sur Booking.com
        </a>
      </ins>
    </div>
  )
}

export default Booking
