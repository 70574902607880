import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Booking from "../components/booking"
import Footer from "../components/footer"
import ShareButton from "../components/shareButton"

export function Head({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter } = markdownRemark
  const title = frontmatter.title
  const description = frontmatter.description
  const path = frontmatter.path
  return (
    <Seo
      title={title}
      description={description}
      slug={path}
      image={`/thumbs/se-loger${path}.png`}
    />
  )
}

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  const title = frontmatter.title
  const city = frontmatter.city
  const path = frontmatter.path
  const url = `${process.env.GATSBY_BASEURL}${path}`
  const lat = frontmatter.lat
  const long = frontmatter.long
  return (
    <Layout content="blog">
      <article>
        <div className="blog-post-container">
          <div className="blog-post">
            <header>
              <div>
                <h1>{title}</h1>
              </div>
            </header>
            <aside>
              <ShareButton title={title} url={url} />
            </aside>
            <main>
              <section>
                <div className="no-print">
                  <h2>La carte des hébergements à {city}</h2>
                  <Booking lat={lat} long={long} />
                </div>
              </section>
              <div
                className="blog-post-content"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </main>
          </div>
        </div>
      </article>
      <Footer city={city} />
    </Layout>
  )
}
export const pageQuery = graphql`
  query ($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "DD/MM/YYYY")
        path
        title
        city
        description
        lat
        long
      }
    }
  }
`
